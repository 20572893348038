import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
// import LogoIcon from '../../images/LogoIcon';
import LogoIcon from '../../images/fjlogo.svg';
import Button from '../Button';
import { Link } from 'gatsby';

const Header = () => (
  <header className="sticky top-0 bg-white shadow">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      
      <div className="flex items-center text-2xl">
        <div className="w-12 mr-3">
          <Link to="/">
            <img src={LogoIcon} />
          </Link>
        </div>
        <Link className="hdrTitle" to="/">
          Floof Journal
        </Link>
      </div>

      <div className="flex mt-4 sm:mt-0">
        <Link className="px-4 menu" to="/">
          Home
        </Link>
        <Link className="px-4 menu" to="/privacy">
          Privacy
        </Link>
        {/* <Link className="px-4 menu" to="/terms">
          Terms
        </Link> */}
        {/* add donate button here */}
      </div>
      <div className="hidden md:block">
        <Button className="text-sm">Shop Now</Button>
      </div>
    </div>
  </header>
);

export default Header;
