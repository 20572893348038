import React from 'react'
import NoHeadLayout from '../components/layout/NoHeadLayout';

import { Link, graphql } from 'gatsby'

import seoLogo from '../images/fjlogo256.png'
import SEO from '../components/SEO'

const seoImage = {
  src: seoLogo,
  height: 256,
  width: 256,
  alt: "Floof Journal Logo",
};

const privacy = (props) => {
  const { data } = props;
  console.log("privacy page props:", props);
  
//   const seoImage = {
//           src: data.defaultBcg.childImageSharp.fixed.src,
//           height: data.defaultBcg.childImageSharp.fixed.height,
//           width: data.defaultBcg.childImageSharp.fixed.width,
//           alt: 'Computer technical words written on a big screen',
//   };

  return (
    <NoHeadLayout>
      <SEO
        siteTitle={'Privacy'}
        seoTitle='Privacy Policy for Floof Journal'
        path={props.location.pathname}
        metaImage={seoImage}
        description='Privacy Policy for Floof Journal'
        keywords={[]}
        datePublished="09-17-2021"
        dateModified="01-15-2022"
      />                    
        
      {/* <StyledHero overlay={true} img={data.defaultBcg.childImageSharp.fluid} /> */}

    <div className="mx-8 my-8">
      <div>
            {/* <Title title="Privacy" subtitle="Policy"/> */}
            <h2>Privacy Policy</h2>
      </div>
      
      <div  className="container">
        <p className="text-md lg:text-md mt-6 font-light">
            This section explains the privacy and cookie policy for this website.
        </p><br />

        <ol>
          <li><h4>Data we collect and how we use it</h4>
          <br />

            {/* <h5 className={styles.subitemName}>Personal Information</h5> */}
            <p>
              <h4>Personal Information: </h4>
              <span className="text-md lg:text-md mt-6 font-light">
                We do not collect your personal information on this website, except as noted below.
              </span>
            </p>
            <br />

            <p>
              <h4>Location Information: </h4>
              <span className="text-md lg:text-md mt-6 font-light">
                We may use anonymized IP address of the visitors for analytical purposes. To comply with GDPR in Europe, and CCPA in California, IP address may be used to determine the general location of the website visitors. This information is used to comply with the consumer privacy laws, such as GDPR in EEA/UK, and CCPA in California. This data may also be shared with Google for analytical purposes. The location data such obtained, is NOT used for tracking or advertisement purposes.
              </span>
            </p>
            <br />

            <p>
              <h4>Anonymous Information: </h4>
              <span className="text-md lg:text-md mt-6 font-light">
                We may use visitors' anonymized IP address to determine the country, browser's user agent string to identify the browser used, and visitor data about page views on our website, etc. This data is used for data analytics, usage metrics, and for spam detection.
              </span>
            </p>
            <br />
            </li>

            <li><h4>Cookie Policy</h4>
                <p>
                  <span className="text-md lg:text-md mt-6 font-light">
                    We may store cookies that only collects anonymous information about the usage of this website, 
                    for analytics and historical data. The cookies are not used to track you across websites and apps, and are not used for advertisements. These cookies may last up to two years.
                  </span>
                </p>
                <br />

                <h4>Embedded content from other websites</h4>
                <p>
                  <span className="text-md lg:text-md mt-6 font-light">
                    This website doesn't include any embedded content at this time, but may do so in the future. 
                    This privacy policy will be updated to include specifics about third party content, when that 
                    happens.
                  </span>
                </p>
                <br />
            </li>

            </ol>

        <div>
          <span className="text-md lg:text-md mt-6 font-light">
            Last Updated: Jan 15, 2022
          </span>
        </div>   
        <br />   

          <div>
            <Link to="/">Back to Home Page</Link>
          </div>
        </div>
      </div>
    </NoHeadLayout>
  )
}

export default privacy

// export const query = graphql`
//   query {
//     defaultBcg: file(relativePath: { eq: "privacyBanner.jpg" }) {
//       childImageSharp {
//         fluid(quality: 90, maxWidth: 2160) {
//           ...GatsbyImageSharpFluid_withWebp
//         }
//         fixed(width: 600, height: 600) {
//           src
//           height
//           width
//         }
//       }
//     }
//   }
// `